<template>
  <el-row class="row" :gutter="20" v-loading="loadinga">
    <el-col :sm="24" :md="16">
      <el-card class="card-left-top">
        <div slot="header" class="clearfix">
          <span>签到板块</span>
        </div>
        <h2 v-if="is_signed_in_today" style="font-size: 40px; color: #40a0ffa0;">
          您今天已经签到过了噢
          <el-button disabled plain style="float: right;" @click="qiandao" v-loading="loadingc">
            立即签到
          </el-button>
        </h2>
        <h2 v-else style="font-size: 40px; color: #F56C6Ca0;">
          您今天尚未签到
          <el-popover placement="left" trigger="click">
            <div id="grecaptcha"></div>
            <el-button type="primary" plain style="float: right;" slot="reference" v-loading="loadingc">
              立即签到
            </el-button>
          </el-popover>
        </h2>
        <p>每日签到即可获取1~300点积分，积分可用于购买增值服务</p>
        <el-divider></el-divider>
        <el-statistic ref="statistic" format="HH:mm:ss" :value="scqdsj" title="距离第二天还剩" time-indices></el-statistic>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="上次签到时间">
                <template slot="formatter">
                  <i class="el-icon-time iconbianda"></i>
                  <p>{{ lastSignInTime }}</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="累计签到积分">
                <template slot="formatter">
                  <i class="el-icon-coin iconbianda"></i>
                  <p>{{ totalPoints }} 分</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="累计签到次数">
                <template slot="formatter">
                  <i class="el-icon-news iconbianda"></i>
                  <p>{{ totalSignIns }} 次</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="今日签到人数">
                <template slot="formatter">
                  <i class="el-icon-user iconbianda"></i>
                  <p>{{ todaySignInCount }} 人</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-card class="card-right">
        <div slot="header">
          <span>个人详情</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="logout">退出登录</el-button>
        </div>
        <div class="jzjzjz">
          <div>
            <el-avatar :size="100" :src="circleUrl"></el-avatar>
          </div>
          <h1>{{ name }}</h1>
          <el-descriptions :column="2">
            <el-descriptions-item label="用户名">{{ name }}</el-descriptions-item>
            <el-descriptions-item label="剩余积分">{{ integral }} 点</el-descriptions-item>
            <el-descriptions-item label="注册邮箱">{{ email }}</el-descriptions-item>
            <el-descriptions-item label="注册QQ">{{ qq }}</el-descriptions-item>
            <el-descriptions-item label="权限组">{{ usergroup }}</el-descriptions-item>
            <el-descriptions-item label="到期时间">{{ term }}</el-descriptions-item>
            <el-descriptions-item label="实名状态">{{ realname }}</el-descriptions-item>
            <el-descriptions-item label="带宽限制">{{ bandwidth }} Mbps</el-descriptions-item>
            <el-descriptions-item label="隧道限制">{{ tunnel }}</el-descriptions-item>
            <el-descriptions-item label="隧道总数">{{ tunnelstate }}</el-descriptions-item>
          </el-descriptions>
          <span>用户唯一标识码：{{ usertoken }}</span>
          <el-divider></el-divider>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="primary" plain @click="showExchangeDialog">兑换码</el-button>
            </el-col>
            <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="danger" plain @click="resetToken">重置token</el-button>
            </el-col>
            <!-- <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="danger" plain @click="rename = true">修改用户名</el-button>
            </el-col> -->
            <!-- <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="drager" plain @click="Temporary_token" v-loading="loadingtt">临时token</el-button>
            </el-col> -->
          </el-row>
        </div>
      </el-card>
      <!-- <el-dialog title="提示" :visible.sync="rename" width="30%">
        <el-form :model="renameform" :label-position="top" label-width="80px">
          <el-form-item label="用户名" prop="newname">
            <el-input v-model="renameform.newname"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-form-item label="验证码" prop="verificationCode">
              <el-col :span="14">
                <el-input v-model="renameform.verificationCode"></el-input>
              </el-col>
              <el-col :span="10">
                <el-button v-loading="loadingyx" :disabled="cooldown > 0" @click="sendVerificationCode"
                  v-if="cooldown === 0">发送验证码</el-button>
                <span v-else>{{ cooldown }}</span>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="rename = false">取消</el-button>
          <el-button type="primary" @click="rename_qd">确定</el-button>
        </span>
      </el-dialog> -->
      <el-dialog title="兑换码" :visible.sync="exchangeDialogVisible" width="30%">
        <el-input v-model="giftcode" placeholder="请输入礼品码"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="exchangeDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="exchangegiftcode" v-loading="loadingb">确定</el-button>
        </span>
      </el-dialog>
      <!-- <el-card class="card-right-x">
      </el-card> -->
    </el-col>
  </el-row>
</template>

<script>
import { setToken, getToken, removeToken } from '@/utils/setToken.js';
import axios from 'axios';

export default {
  data() {
    return {
      sitekey: "6LetQ4YoAAAAANuUUzVy-ID4rQ8YhYmotBFUhNIj",
      rename: false,
      loadingyx: false,
      loadinga: false,
      loadingb: false,
      loadingc: false,
      loadingtt: false,
      is_signed_in_today: false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      giftcode: '',
      exchangeDialogVisible: false,
      scqdsj: Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
      todaySignInCount: '',
      totalSignIns: '',
      totalPoints: '',
      lastSignInTime: '',
      cooldown: 0,
      top: '',
      renameform: {
        newname: '',
        verificationCode: '',
      },
    }
  },

  mounted(params) {
    this.loaded();
    const userid = getToken('userid');
    axios
      .get('https://cf-v1.uapis.cn/api/qdxx.php', {
        params: { userid, ...params },
      })
      .then(response => {
        this.lastSignInTime = response.data.last_sign_in_time;
        this.totalPoints = response.data.total_points;
        this.totalSignIns = response.data.total_sign_ins;
        this.todaySignInCount = response.data.count_of_matching_records;
        this.is_signed_in_today = response.data.is_signed_in_today;
      })
  },

  created() {
    this.generateRandomString();
    const usertoken = getToken('token');
    this.email = getToken('email');
    this.qq = getToken('qq');
    this.realname = getToken('realname');
    this.usergroup = getToken('usergroup');
    this.term = getToken('term');
    this.tunnel = getToken('tunnel');
    this.bandwidth = getToken('bandwidth');
    this.tunnelstate = getToken('tunnelstate');
    this.usertoken = getToken('token');
    this.name = getToken('username');
    this.circleUrl = getToken('userimg');
    this.integral = getToken('integral');

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },
  methods: {
    generateRandomString() {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < 12; i++) {
        let randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      this.randomString = result;
    },

    setData(params) {
      this.loadinga = true;
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadinga = false;
        })
    },
    qiandao: function (g_recaptcha_response) {
      this.loadingc = true;
      const usertoken = getToken('token');

      const formData = new FormData();
      formData.append('usertoken', usertoken);
      formData.append('g_recaptcha_response', g_recaptcha_response);

      axios
        .post('https://cf-v1.uapis.cn/api/qiandao.php', formData)
        .then(response => {
          if (response.data.status === 'fail') {
            this.$message({
              message: response.data.message,
              type: "error",
            });
          } else {
            this.$alert('您本次签到获得积分：' + response.data.points, '签到成功', {
              confirmButtonText: '确定',
              type: 'success',
            }).then(() => {
              this.$message({
                type: 'success',
                message: '签到成功'
              });
              location.reload();
            });
          }
        }).finally(() => {
          this.loadingc = false;
        });
    },
    resetToken() {
      // 弹出确认框
      this.$confirm('确定要重置token吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        // 用户点击确定，调用重置token的API
        this.loadinga = true;
        const usertoken = getToken('token');
        axios
          .get('https://cf-v1.uapis.cn/api/resusertoken.php', {
            params: { usertoken: usertoken }
          })
          .then(response => {
            if (response.data.error) {
              this.$message({
                message: "重置token失败：" + response.data.error,
                type: "error",
              });
            } else {
              // 重置token成功
              this.setData();
              this.$message({
                message: "重置token成功",
                type: "success",
              });
            }
          })
          .catch(error => {
            console.error('请求API时发生错误:', error);
            this.$message({
              message: "重置失败，请求API时发送错误",
              type: "error",
            });
          })
          .finally(() => {
            this.loadinga = false;
          });
      }).catch(() => {
        // 用户点取消
      });
    },
    Temporary_token() {
      const usertoken = getToken('token');
      this.loadingtt = true; // 开始按钮加载状态
      axios
        .get('https://cf-v1.uapis.cn/api/temporary_token.php', {
          params: { usertoken: usertoken }
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.$confirm('临时token在frpc中使用一次将会直接删除，无法再继续使用。此外临时token最多保留一份，且此次显示过后不会再显示。您的token为：' + response.data.t_newToken, '临时token获取成功', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
            // 限制发送时间
            this.cooldown = 60;
            const cooldownInterval = setInterval(() => {
              if (this.cooldown > 0) {
                this.cooldown--;
              } else {
                clearInterval(cooldownInterval);
              }
            }, 1000);
          } else {
            this.$message({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadingtt = false; //结束按钮加载状态
        })
    },
    // sendVerificationCode() {
    //   this.loadingyx = true;
    //   const ppassword = 'CHMLFRP-YOUXIANGYANZHENGMA-MD5PASSWORD'
    //   const concatenatedString = this.renameform.email + this.randomString + ppassword;
    //   this.encryptedPassword = MD5(concatenatedString).toString();

    //   const params = {
    //     lc: this.randomString,
    //     email: this.renameform.email,
    //     md5: this.encryptedPassword,
    //   };

    //   axios
    //     .get('https://cf-v1.uapis.cn/api/email.php', { params })
    //     .then(response => {
    //       if (response.data.status === 'success') {
    //         this.$message({
    //           message: "验证码发送成功",
    //           type: "success",
    //         });
    //         // 限制发送时间
    //         this.cooldown = 60;
    //         const cooldownInterval = setInterval(() => {
    //           if (this.cooldown > 0) {
    //             this.cooldown--;
    //           } else {
    //             clearInterval(cooldownInterval);
    //           }
    //         }, 1000);
    //       } else {
    //         this.$message({
    //           message: response.data.message,
    //           type: "error",
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.error('请求API时发生错误:', error);
    //     })
    //     .finally(() => {
    //       this.loadingyx = false; //结束按钮加载状态
    //     })
    // },

    rename_qd() {
      if (
        !this.renameform.newname ||
        !this.renameform.verificationCode
      ) {
        this.$message({
          message: "请填写完整的信息",
          type: "warning",
        });
        return;
      }

      this.loadingzc = true;
      const params = {
        usertoken: this.usertoken,
        newname: this.renameform.newname,
        verificationCode: this.renameform.verificationCode,
        lc: this.randomString,
        operate: '注意！您正在修改用户名'
      };

      axios
        .get('https://cf-v1.uapis.cn/api/rename.php', { params })
        .then(responsea => {
          if (responsea.data.success) {
            this.$message({
              message: "用户名修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: responsea.data.message,
              type: "error",
            });
          }
        })
        .catch(responsea => {
          console.error('请求API时发生错误:', responsea.data.message);
        })
        .finally(() => {
          this.loadingzc = false; // 结束按钮加载状态
        });
    },

    // 点击“兑换码”按钮后弹出输入礼品码的弹窗
    showExchangeDialog() {
      this.exchangeDialogVisible = true;
    },
    // 提交礼品码
    exchangegiftcode() {
      // 关闭兑换码弹窗
      this.loadingb = true;

      // 调用API，并传递礼品码和其他参数
      const usertoken = getToken('token');
      const userid = getToken('userid');
      axios
        .get('https://cf-v1.uapis.cn/api/giftcode.php', {
          params: { usertoken, userid, giftcode: this.giftcode },
        })
        .then(response => {
          if (response.data.success === true) {
            this.$message({
              message: "兑换码兑换成功",
              type: "success",
            });
          } else {
            this.$message({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loadingb = false;
          this.exchangeDialogVisible = false;
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
          this.$message({
            message: "兑换礼品码失败，请求API时发送错误",
            type: "error",
          })
        })
    },
    logout() {
      // 弹出确认框
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.success('退出登录成功')
        removeToken('token');
        removeToken('username');
        removeToken('userid');
        removeToken('userimg');
        removeToken('email');
        removeToken('tunnel');
        removeToken('usergroup');
        removeToken('qq');
        removeToken('bandwidth');
        removeToken('realname');
        removeToken('integral');
        removeToken('term');
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '您已取消'
        });
      });
    },
    loaded() {
      setTimeout(() => {
        window.grecaptcha.render("grecaptcha", {
          sitekey: this.sitekey,
          callback: this.qiandao
        });
      }, 200);
    }
  }
}
</script>

<style lang="less" scoped>
.jzjzjz {
  text-align: center;
}

.bolder {
  font-weight: bolder;
}

.fa {
  font-size: 25px;
}

.row {
  min-height: 340px;

  .iconbianda {
    font-size: 50px;
  }

  .card-left-top {
    min-height: 340px;
  }

  .card-right-x {
    min-height: 200px;
    margin-top: 16px;
  }

  .card-right {
    min-height: 340px;
  }
}
</style>